var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    staticClass: "rounded cursor-pointer p-75 border-0 w-100 rounded-lg",
    attrs: {
      "id": "popover-no-of-passenger",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.onTogglePopover('btn');
      }
    }
  }, [_c('span', {
    staticClass: "text-white text-nowrap"
  }, [_vm._v(" Số lượng hành khách ")])]), _c('b-popover', {
    attrs: {
      "id": "popoverAddPassenger",
      "show": _vm.showPopover,
      "target": "popover-no-of-passenger",
      "placement": "bottom",
      "variant": "warning",
      "triggers": "click",
      "boundary": "window"
    },
    on: {
      "show": _vm.handleShowPopoverAddPaxs
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.numPassenger')) + " "), _c('span', {
          staticClass: "p-25 cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.onTogglePopover('x');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon",
            "size": "20"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.quantityPaxs),
      "spinner-variant": 'info'
    }
  }, [_vm._l(_vm.quantityPaxs, function (type, indexType) {
    return _c('b-row', {
      key: indexType,
      staticClass: "mb-75 mx-0"
    }, [_c('b-col', {
      attrs: {
        "cols": "5 d-flex align-items-center px-0"
      }
    }, [_c('strong', {
      staticClass: "text-nowrap"
    }, [_vm._v(_vm._s(_vm.$t("flight.".concat(_vm.capitalize(type.key)))))])]), _c('b-col', {
      attrs: {
        "cols": "7 pl-0"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-button', {
      staticClass: "btn-icon p-50 rounded-lg",
      attrs: {
        "id": "button-decrease-".concat(type.key),
        "variant": "gradient-warning"
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangeQuantity(type, 'decrease');
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon"
      }
    })], 1), _c('span', {
      staticClass: "px-1"
    }, [_vm._v(" " + _vm._s(type.value) + " ")]), _c('b-button', {
      staticClass: "btn-icon p-50 rounded-lg",
      attrs: {
        "id": "button-increase-".concat(type.key),
        "variant": "gradient-warning"
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangeQuantity(type, 'increase');
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    })], 1)], 1)])], 1);
  }), _c('div', {
    staticClass: "d-flex-center mt-25 pt-50 border-top"
  }, [_c('b-button', {
    staticClass: "rounded-lg py-75 mr-1",
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onTogglePopover('x');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Cancel')) + " ")]), _c('b-button', {
    staticClass: "rounded-lg py-75",
    attrs: {
      "variant": "success",
      "size": "sm",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.handleSubmitChange
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_vm.isDisabled ? _c('b-spinner', {
    staticClass: "mr-50",
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.$t('flight.confirm')))])], 1)])], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
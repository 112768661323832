<template>
  <div>
    <b-button
      id="popover-no-of-passenger"
      class="rounded cursor-pointer p-75 border-0 w-100 rounded-lg"
      variant="warning"
      @click="onTogglePopover('btn')"
    >
      <span class="text-white text-nowrap">
        Số lượng hành khách
      </span>
    </b-button>

    <b-popover
      id="popoverAddPassenger"
      :show="showPopover"
      target="popover-no-of-passenger"
      placement="bottom"
      variant="warning"
      triggers="click"
      boundary="window"
      @show="handleShowPopoverAddPaxs"
    >
      <!-- triggers="focus hover" -->
      <template #title>
        <div class="d-flex-between">
          {{ $t('flight.numPassenger') }}
          <span
            class="p-25 cursor-pointer"
            @click="onTogglePopover('x')"
          >
            <feather-icon
              icon="XIcon"
              size="20"
            />
          </span>
        </div>
      </template>

      <IAmOverlay
        :loading="isEmpty(quantityPaxs)"
        :spinner-variant="'info'"
      >
        <b-row
          v-for="(type, indexType) of quantityPaxs"
          :key="indexType"
          class="mb-75 mx-0"
        >
          <b-col cols="5 d-flex align-items-center px-0">
            <strong class="text-nowrap">{{ $t(`flight.${capitalize(type.key)}`) }}</strong>
          </b-col>

          <b-col cols="7 pl-0">
            <div class="d-flex justify-content-between align-items-center">
              <b-button
                :id="`button-decrease-${type.key}`"
                variant="gradient-warning"
                class="btn-icon p-50 rounded-lg"
                @click="handleChangeQuantity(type, 'decrease')"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
              <span class="px-1">
                {{ type.value }}
              </span>
              <b-button
                :id="`button-increase-${type.key}`"
                variant="gradient-warning"
                class="btn-icon p-50 rounded-lg"
                @click="handleChangeQuantity(type, 'increase')"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex-center mt-25 pt-50 border-top">
          <b-button
            variant="outline-danger"
            size="sm"
            class="rounded-lg py-75 mr-1"
            @click="onTogglePopover('x')"
          >
            {{ $t('flight.Cancel') }}
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="rounded-lg py-75"
            :disabled="isDisabled"
            @click="handleSubmitChange"
          >
            <div class="d-flex-center">
              <b-spinner
                v-if="isDisabled"
                class="mr-50"
                small
              />
              <span class="text-nowrap">{{ $t('flight.confirm') }}</span>
            </div>
          </b-button>
        </div>
      </IAmOverlay>
    </b-popover>
  </div>
</template>

<script>
import {
  BPopover,
  BButton,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import {
  ref, toRefs, inject, watch, computed,
} from '@vue/composition-api'
import {
  map, isEmpty, capitalize, cloneDeep,
} from 'lodash'

import store from '@/store'

import useToast from '@useToast'

export default {
  components: {
    BPopover,
    BButton,
    BRow,
    BCol,
    BSpinner,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    paxLists: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const { paxLists } = toRefs(props)
    const { toastError } = useToast()
    // const firstOfListPax = paxLists.value

    const showPopover = ref(false)
    const quantityPaxs = ref(null)

    const isDisabled = ref(false)
    const checkBtn = ref(0)

    const onTogglePopover = act => {
      if (act === 'x') {
        showPopover.value = false
        return
      }
      showPopover.value = !showPopover.value
    }

    const hidePopoverChangeQuantityPaxEvent = inject('hidePopoverChangeQuantityPaxEvent')
    watch(() => hidePopoverChangeQuantityPaxEvent.value, () => {
      onTogglePopover('x') // Đóng cái popover khi ấn vào xoá hành khách, nhập tên nhanh, ...
    })

    // TODO: case nếu số lượng = 0 k show
    // nhưng search data đã có paxType thì hiển thị lên, fix vào getListPaxShow cho default value
    const getListPaxShow = paxLists => {
    //   const paxTypeCounts = mapValues(groupBy(firstOfListPax, 'paxType'), list => list.length)
      const paxTypeCounts = { ADULT: 0, CHILD: 0, INFANT: 0 }

      //   if (!paxTypeCounts.CHILD) {
      //     paxTypeCounts.CHILD = 0
      //   }

      //   if (!paxTypeCounts.INFANT) {
      //     paxTypeCounts.INFANT = 0
      //   }

      const data = map(paxTypeCounts, (value, key) => {
        const count = paxLists.filter(p => p.paxType === key).length
        return { key, value: count }
      })

      return data
    }

    const handleShowPopoverAddPaxs = () => { // Show popover
      isDisabled.value = false
      checkBtn.value = 0
      quantityPaxs.value = null
      quantityPaxs.value = getListPaxShow(paxLists.value)
    }
    const limitPaxBooking = computed(() => store.getters['userStore/getLimitPaxBooking'])

    const handleChangeQuantity = (type, action) => {
      const totalAdult = quantityPaxs.value.find(p => ['ADULT'].includes(p.key))?.value || 0
      const totalAdultAndChild = quantityPaxs.value.filter(p => ['ADULT', 'CHILD'].includes(p.key)).reduce((total, item) => total + item?.value || 0, 0)
      const totalInfant = quantityPaxs.value.find(p => ['INFANT'].includes(p.key))?.value || 0
      const draftData = cloneDeep(quantityPaxs.value)
      const result = draftData.map(item => {
        if (item.key === type.key) {
          if (['increase'].includes(action)) {
            if (['ADULT', 'CHILD'].includes(type.key) && totalAdultAndChild >= limitPaxBooking.value) {
              toastError({ title: root.$t('flight.passengerMore', { limitPax: limitPaxBooking.value }) })
            } else if (['INFANT'].includes(type.key) && (totalInfant >= totalAdult)) {
              toastError({ title: 'Số em bé không được nhiều hơn số lượng hành khách người lớn.' })
            } else {
              item.value += 1
            }
          } else if (['decrease'].includes(action) && type.value > 0) {
            if (['ADULT'].includes(type.key)) {
              if (item.value > 1) {
                if (totalInfant >= totalAdult) {
                  draftData.find(p => ['INFANT'].includes(p.key)).value = item.value - 1
                }
                item.value -= 1
              }
            } else {
              item.value -= 1
            }
          }
        }
        return item
      })
      quantityPaxs.value = result
    }

    function getChangedValues(oldValue, newValue) {
      const changedValues = []
      for (let i = 0; i < oldValue.length; i += 1) {
        const oldItem = oldValue[i]
        const newItem = newValue.find(item => item.key === oldItem.key)
        if (newItem && oldItem.value !== newItem.value) {
          const changeInfo = { key: oldItem.key }
          if (newItem.value < oldItem.value) {
            changeInfo.action = 'decrease'
            changeInfo.target = oldItem.value - newItem.value
          } else {
            changeInfo.action = 'increase'
            changeInfo.target = newItem.value - oldItem.value
          }
          changedValues.push(changeInfo)
        }
      }
      return changedValues
    }

    function handleSubmitChange() {
      isDisabled.value = true

      // this.$bvModal.show('modal-api-loading')

      const oldQuantity = getListPaxShow(paxLists.value)
      const changedValues = getChangedValues(oldQuantity, quantityPaxs.value)
      if (!isEmpty(changedValues)) {
        checkBtn.value += 1
        if (checkBtn.value === 1) {
          emit('changeQuantityPax', changedValues)
          onTogglePopover('x')

          setTimeout(() => {
            isDisabled.value = false
            // this.$bvModal.hide('modal-api-loading')
          }, 1000)
        }
      }
      setTimeout(() => {
        isDisabled.value = false
        // this.$bvModal.hide('modal-api-loading')
      }, 500)
    }

    return {
      isEmpty,
      capitalize,
      showPopover,
      quantityPaxs,
      onTogglePopover,

      isDisabled,

      handleSubmitChange,
      handleChangeQuantity,
      handleShowPopoverAddPaxs,
    }
  },
}
</script>

<style lang="scss" scoped>
#popover {
    max-width: none;
}
</style>
